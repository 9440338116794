// Colors
$grey: #666;
// $dark-grey: #333;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;

// Font Size
// $font-size-small: 1.4rem;
// $font-size-large: 1.8rem;
$font-size-small: 0.8rem;
$font-size-large: 1.0rem;

// Spacing
$s-size: 0.7rem;
$m-size: 0.9rem;
$l-size: 1.7rem;
$xl-size: 2.7rem;
$desktop-breakpoint: 45rem;