.history-section-button {
  padding: 0;
  color: $dark-grey;
}

.history-section-button-bold {
  font-weight: 700;
}

.history-section-button:hover {
  color: $khaki;
  text-decoration: none;
}

.history-section-button:focus {
  color: $khaki;
  text-decoration: none;
}

.history-section-button:active {
  color: $khaki;
  text-decoration: none;
}
