.footer-social-title {
  margin-bottom: 10px;
}

.footer-social-links {
  background-color: $cream;
  padding: 10px;
  border-radius: 0 0 50px 50px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  transition: background-color 0.5s ease;

  @media (min-width: $desktop-breakpoint) {
    border-radius: 0 0 50px 50px;
    width: 30%;
    margin: 0 auto;
    text-align: center;
    transition: background-color 0.5s ease;
  }

  a {
    padding-right: 20px;
  }

  path {
    color: $dark-grey;
    transition: color 0.5s ease;

    &:hover {
      color: $khaki;
    }
  }
}

.social-subscribe-wrapper {
  background-color: rgba(0, 0, 0, 0.5) !important;
  // border: $dark-grey 1px solid;
  border-radius: 10px;
  // margin: 20px;
  margin-bottom: 50px;
  padding: 20px;

  .fb-like {
    display: flex;
    justify-content: center;
  }

  @media (min-width: $desktop-breakpoint) {
    display: none;
  }
}

.social-subscribe-wrapper-fixed-right {
  position: fixed;
  top: 60%;
  right: 0%;
  // -webkit-transform: translateY(-50%);
  // -ms-transform: translateY(-50%);
  // transform: translateY(-50%);
  z-index: 1000;
  
  background-color: rgba(0, 0, 0, 0.5) !important;
  // border: $dark-grey 1px solid;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  // margin: 15px;
  padding: 10px;

  .fb-like {
    display: flex;
    justify-content: center;
  }
}

.social-subscribe-wrapper-fixed-bottom-right {
  position: fixed;
  bottom: 0%;
  right: 0%;
  z-index: 1000;
  
  // background-color: rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to right, rgba(0,0,0,0) 1%, rgba(0, 0, 0, 0.8) 99%);
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  padding-left: 100px;

  .fb-like {
    margin: 5px;
  }

  @media (max-width: $desktop-breakpoint - 1) {
    display: none;
  }
}