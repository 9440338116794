.footer {
  background-image: linear-gradient(lighten($beige, 10), darken($beige, 10));
  color: $dark-grey;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .column {
    padding: auto;
    width: 100%;
    display: flex;

    @media (min-width: $desktop-breakpoint) {
      height: 250px;
      width: 33.33%;
    }
  }

  .left {
    float: left;
    padding: 10px 30px 10px 30px;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $desktop-breakpoint) {
      padding: 10px 10px 10px 50px;
      width: 30%;
    }
  }

  .middle {
    float: left;
    padding: 30px 50px 10px 50px;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    @media (min-width: $desktop-breakpoint) {
      width: 40%;
    }
  }

  .right {
    float: right;
    padding: 10px 30px 50px 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $desktop-breakpoint) {
      flex-direction: row;
      justify-content: flex-end;
      padding: 10px 50px 10px 10px;
      width: 30%;
    }
  }
}

.footer-logo {
  height: 100px;
  border-radius: 15px;
}

.footer-links {
  display: block;
  padding-bottom: 30px;
  text-align: center;

  @media (min-width: $desktop-breakpoint) {
    text-align: left;
  }
}

.footer-links a {
  color: $dark-grey;
}

.footer-links a:hover {
  color: $khaki;
  text-decoration: none;
  border-bottom: 1px solid $khaki;
}

.footer-link-slash {
  padding-left: 10px;
  padding-right: 10px;
}

.on-left-row-components {
  display: flex;
  align-items: flex-start;
  margin: 15px 15px 15px 0;
  padding-left: 0;

  p {
    margin: auto 0;
  }

  .icon {
    height: 50px;
    width: 50px;
    margin: auto 15px auto 0;
  }
}

.height-components {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.simple-component {
  text-align: center;
  margin: auto;
}

.copyright-component {
  @extend .simple-component;
  background-image: linear-gradient(lighten($dark-grey, 10), darken($dark-grey, 10));
  color: $cream;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;

  p {
    padding: 5px 0;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 400;
  }
}

.iban-component {
  p {
    font-family: Montserrat, Copperplate, Helvetica, Arial, sans-serif !important;
  }
}
