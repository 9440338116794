.box-layout {
    align-items: center;
    background: url('/images/bg.jpg');
    background-size: cover;
    display: flex;
    height: 100vh;  // viewport height
    justify-content: center;
    width: 100vw;   // viewport width
}

.box-layout__box {
    background: fade-out($color: white, $amount: .15);
    border-radius: 3px;
    padding: $l-size $m-size;
    text-align: center;
    width: 25rem;
}

.box-layout__title {
    margin: 0 0 $m-size 0;
    line-height: 1;
}