.auth-lock-container {
  box-sizing: initial;
  // text-rendering: optimizeLegibility;
  // pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  display: table;
  width: 100%;
  height: 100%;
  right: 0;
  // z-index: 1000000;

  .auth-lock-center {
    vertical-align: middle;
    display: table-cell;
    margin: auto;
    overflow-y: auto;

    .auth-lock-widget {
      box-sizing: initial;
      display: block;
      width: 300px;
      // opacity: 0;
      // transform: translateY(100%) scale(0.8);
      // transition-timing-function: cubic-bezier(0.3, 0, 0, 1.4);
      margin: 0 auto;
      border-radius: 5px;
      opacity: 1;
      transform: translateY(0%) scale(1);
      transition: transform 0.6s, opacity 0.6s;
      transition-delay: 0.5s;
      box-shadow: 0 0 40px 4px $dark-grey; //#111118;

      .auth-container {
        box-sizing: initial;
        display: block;
        position: relative;
        margin: 0;

        .internal-wrapper {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 40px);
          min-height: auto;
          height: auto;

          .auth-header {
            text-align: center;
            padding: 11px;
            color: $cream;
            // position: relative;
            background-image: linear-gradient(lighten($beige, 10), darken($beige, 10));
            border-radius: 5px 5px 0 0;
            overflow: hidden;
            box-sizing: border-box;
            // display: -ms-flexbox;
            display: flex;
            // -ms-flex-direction: column;
            flex-direction: column;
            // -ms-flex-pack: center;
            justify-content: center;
            // -ms-flex-negative: 0;
            // flex-shrink: 0;
            // -ms-flex-positive: 0;
            flex-grow: 0;
            // -ms-flex-preferred-size: auto;
            flex-basis: auto;
            position: absolute;
            width: 100%;
            z-index: 100;

            .my-auth-logo {
              @extend .my-navbar-logo;
              height: 80px;
              align-self: center;
              padding-bottom: 10px;
            }

            p {
              margin-bottom: 0;
            }
          }

          .content-wrapper {
            flex-grow: 1;
            // -ms-flex-negative: 1;
            flex-shrink: 1;
            // -ms-flex-preferred-size: auto;
            flex-basis: auto;
            // -webkit-overflow-scrolling: touch;
            overflow-x: auto;

            .body-wrapper {
              display: block;
              margin-top: 111px;
              padding-top: 10px;
              background-image: linear-gradient(lighten($cream, 10), darken($cream, 10));

              .form-body {
                animation: fadeIn 0.2s;
                display: block;
                padding: 20px;

                .form-cell {
                  display: block;
                  margin-bottom: 10px;
                  position: relative;

                  .form-input {
                    background-color: darken($cream, 10);
                    border: 0;
                    padding: 0 14px;
                    right: 0;
                    height: 40px;
                    font-size: 13px;
                    width: 100%;
                    border-radius: 5px;
                    box-sizing: border-box;
                    position: relative;
                    color: rgba(0, 0, 0, 0.87);
                  }
                }
              }
            }
          }

          .submit-button {
            background: linear-gradient(lighten($khaki, 10), darken($khaki, 10));
            border: 0;
            padding: 14px;
            display: block;
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
            border-radius: 0 0 5px 5px;
            transition: 0.5s ease-in-out;
            color: $cream;
            letter-spacing: 1px;
            font-size: 14px;
            text-transform: uppercase;
            // -ms-flex-negative: 0;
            flex-shrink: 0;
            // -ms-flex-positive: 0;
            flex-grow: 0;
            // -ms-flex-preferred-size: auto;
            flex-basis: auto;

            &:hover {
              font-size: 18px;
            }

            &:disabled {
              cursor: not-allowed;
              background: $dark-grey;
            }
          }
        }
      }
    }
  }
}

// Google Button
#customBtn {
  display: inline-block;
  background: $google-blue;
  color: white;
  width: 100%;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
  // transition: background .5s ease-in-out, color .5s ease-in-out;

  span.icon {
    background: url("/images/buttons/btn_google_dark_normal_ios.svg") transparent 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    // width: 42px;
    height: 42px;
    // width: 55px;
    width: 42px;
    transition: background 0.5s ease-in-out;
  }

  &:hover {
    cursor: pointer;
    background: darken($color: $google-blue, $amount: 5);

    span.icon {
      background: url("/images/buttons/btn_google_dark_focus_ios.svg") transparent 50% no-repeat;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: white;
    color: #444;

    span.icon {
      background: url("/images/buttons/btn_google_dark_disabled_ios.svg") transparent 50% no-repeat;
    }
  }
}

span.label {
  font-family: Montserrat, serif;
  font-weight: normal;
}

span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: Montserrat, Roboto, sans-serif;
}
