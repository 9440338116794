.my-navbar {
  background-color: transparent;
  padding: 5px 10px;
  transition: background-color 0.5s ease;

  &.scrolled {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (min-width: $desktop-breakpoint) {
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.navbar-brand {
  color: $cream;
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 0;
  text-transform: uppercase;
}

.navbar-toggler {
  background-color: black;
  opacity: 0.3;
  transition: background-color 0.5s ease;
}

.navbar-toggler:hover {
  background-color: $khaki;
}

.my-navbar-logo {
  width: auto;
  height: 40px;

  @media (min-width: 330px) {
    width: auto;
    height: 45px;
  }

  @media (min-width: $desktop-breakpoint) {
    width: auto;
    height: 60px;
  }
}

.my-navbar-collapse {
  align-self: flex-end;
}

.nav-item {
  padding: 5px 10px;

  @media (max-width: $desktop-breakpoint - 1) {
    background-color: black;
    opacity: 0.7;
    margin: 0px;
    padding: 10px;
  }
}

.nav-item:hover {
  @media (max-width: $desktop-breakpoint - 1) {
    background-color: $khaki;
    color: $dark-grey;
  }
}

.nav-link {
  font-family: Copperplate, Montserrat, Helvetica, Verdana, Geneva, Tahoma,
    Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 100;
  color: $cream;
  transition: color 0.5s ease;
}

.nav-link:hover {
  color: darken($color: $cream, $amount: 20);
}

.nav-link.active {
  @media (max-width: $desktop-breakpoint - 1) {
    font-weight: 1000;
    color: $khaki;
  }

  @media (min-width: $desktop-breakpoint) {
    border-bottom: 1px solid $cream;
  }
}

.nav-link-highlighted-red {
  color: $crimson-red;
  font-weight: 1000;
  letter-spacing: 2px;
  transition: background-color 0.5s ease;

  @media (min-width: $desktop-breakpoint) {
    border: 1px solid $crimson-red;
    border-radius: 4px;
  }
}

.nav-link-highlighted-red.active,
.nav-link-highlighted-red:hover {
  @media (min-width: $desktop-breakpoint) {
    color: $crimson-red;
    background-color: $khaki;
    border-color: $khaki;
  }
}

.nav-link-highlighted-beige {
  @extend .nav-link-highlighted-red;
  background-color: transparent;
  color: $cream;

  @media (min-width: $desktop-breakpoint) {
    border: 1px solid $cream;
  }
}

.nav-link-highlighted-beige.active,
.nav-link-highlighted-beige:hover {
  // @extend .nav-link-highlighted-beige.active;
  color: $cream;

  @media (min-width: $desktop-breakpoint) {
    background-color: $dark-grey;
    border-color: $dark-grey;
  }
}

.login-btn {
  font-weight: 500;
  border-style: none;
}

.header-space {
  border-top: 80px solid rgba(0, 0, 0, 0.5);
}
