.image {
  height: 20%;
  width: 20%;
  margin: 15px;
}

.schedule {
  padding-top: 5%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  @media (min-width: $desktop-breakpoint) {
    align-items: flex-start;
    flex-direction: row;
  }
}

.observations {
  margin-bottom: 5%;
}

.red-content {
  border: $crimson-red 1px solid;
  border-radius: 10px;
  padding: 20px;
  color: $crimson-red;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: darken($color: $cream, $amount: 5);
  }

  td {
    color: $crimson-red;
  }
}
