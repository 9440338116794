.page-sidebar-wrapper {
  min-height: 400px;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (min-width: $desktop-breakpoint) {
    min-height: 400px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.page-sidebar-menu-list {
  list-style-type: none;
  padding: 0px;
}

.page-sidebar-section h1 {
  font-weight: 700;
  margin-bottom: 20px;
}
