// NOTE: the order of these styles DO matter

.DateInput_input {
  background: linear-gradient(lighten($cream, 10), darken($cream, 10)); //background
  border-bottom: 2px solid darken($cream, 10);
}

.DateRangePickerInput {
  background: linear-gradient(lighten($cream, 10), darken($cream, 10)); //background
}

.DateInput_input__focused {
  border-bottom: 2px solid $beige;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  background: $cream; //background
}

.DayPickerNavigation_rightButton__horizontalDefault {
  background: $cream; //background
}

.CalendarMonthGrid {
  background: linear-gradient(lighten($cream, 10), darken($cream, 10)); //background
  // background: $cream; //background
}

.DayPicker_transitionContainer {
  // background: $cream; //background
  background: linear-gradient(lighten($cream, 10), darken($cream, 10)); //background
}

.CalendarMonth {
  // background: $cream; //background
  background: linear-gradient(lighten($cream, 10), darken($cream, 8)); //background
}

.CalendarDay__default {
  background: $cream; //background
  border: 1px solid $cream; //default styles include a border
}

.CalendarDay__default:hover {
  background: lighten($khaki, 30); //background
  // opacity: .1;
  border: 1px solid $cream; //default styles include a border
  // color: $cream; //text
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: $beige; //background
  border: 1px solid $cream; //default styles include a border
  color: $cream; //text
}

.CalendarDay__selected_span:hover {
  background: $khaki; //background
  border: 1px solid $cream; //default styles include a border
  color: $cream; //text
}
 
// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $khaki;
  border: 1px solid $cream; //default styles include a border
  color: $cream;
}
 
// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $khaki;
  border: 1px solid $cream; //default styles include a border
  color: $cream;
}
 
// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $dark-grey;
  border: 1px solid $cream; //default styles include a border
  color: $cream;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid $beige;
}

.DayPickerKeyboardShortcuts_show__bottomRight::after {
  border-top: 26px solid transparent;
  border-right: 33px solid $beige;
}

.DayPickerKeyboardShortcuts_showSpan {
  color: $cream;
}