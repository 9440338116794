.page-fragment {
  text-align: center;
  padding: 50px 45px;

  @media (min-width: $desktop-breakpoint) {
    padding: 50px 100px;
  }

  h1 {
    font-weight: 200;
    font-style: normal;
    color: $khaki;
    margin-top: 20px;
  }

  h2 {
    font-weight: 700;
    color: $khaki;
    padding-bottom: 12px;
  }

  div {
    font-style: italic;
    font-size: 22px;
    padding-bottom: 30px;
  }
}