.page-banner {
  margin-left: -10px;
  margin-right: -10px;
  min-height: 300px;
  background-size: cover !important;
  // margin-bottom: 20px;

  @media (min-width: $desktop-breakpoint) {
    margin-left: -100px;
    margin-right: -100px;
    min-height: 350px;
    background-position: center !important;
  }
}

.page-banner-mobile-center {
  background-position: center !important;
  line-height: 0;
}

.page-banner-mobile-right {
  background-position: right !important;
}

.page-banner h1 {
  text-align: center;
  font-weight: 700;
}

.page-banner .row {
  height: 300px;

  @media (min-width: $desktop-breakpoint) {
    height: 350px;
  }
}
