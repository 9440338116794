.our-button {
  color: $dark-grey;
  margin: 0 auto;
  border-color: $khaki !important;
  margin-bottom: 30px;
  box-shadow: none;
  transition: background-color 0.5s ease;
  text-align: center;
}

.our-button:hover {
  background-color: $khaki !important;
  color: $cream;
}

.our-button:active {
  background-color: $khaki !important;
  border-color: $khaki !important;
  color: $cream;
}

.our-button:focus {
  box-shadow: none;
}

.button-on-right {
  @extend .our-button;
  margin: 0;
}