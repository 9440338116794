.page {
  margin: 0 10px;

  @media (min-width: $desktop-breakpoint) {
    margin: 0 100px;
  }
}

.page h2 {
  font-weight: 700;
  margin-bottom: 25px;
}

.page-bible-reference {
  font-weight: 700;
  color: $khaki;
}

// This tag enables the header space inside the page tag
.page-header-space {
  border-top: 80px solid rgba(0, 0, 0, 0.5);
  margin-left: -10px;
  margin-right: -10px;

  @media (min-width: $desktop-breakpoint) {
    margin-left: -100px;
    margin-right: -100px;
  }
}
