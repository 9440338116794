.list-body {
    margin-bottom: $m-size;
    @media (min-width: $desktop-breakpoint) {
        margin-bottom: $l-size;
    }
}

.list-header {
    // background: $beige;
    background-image: linear-gradient(lighten($beige, 10), darken($beige, 10));
    border: 1px solid darken($off-white, 7%);
    color: $cream;
    display: flex;
    justify-content: space-between;
    padding: $s-size $m-size;
}

.list-item {
    // background: lighten($color: $cream, $amount: 5);
    align-items: center;
    background-image: linear-gradient(lighten($cream, 5), darken($cream, 5));
    border: 1px solid darken($cream, 5%);
    border-top: none;
    color: $dark-grey;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: $s-size;
    text-decoration: none;
    transition: background-image 0.5s ease-in-out;

    &:hover {
        // background: darken($color: $cream, $amount: 5);
        background-image: linear-gradient(lighten($cream, 10), darken($cream, 10));
    }

    @media (min-width: $desktop-breakpoint) {
        flex-direction: row;
        justify-content: flex-start;
        padding: $m-size;
    }
}

.list-item__title {
    margin: 0;
    word-break: break-word;
}

.list-item__sub-title {
    color: lighten($color: $dark-grey, $amount: 30);
    font-size: $font-size-small;
}

.list-item__thumbnail {
    border: 1px solid $khaki; /* Gray border */
    border-radius: 4px; /* Rounded border */
    object-fit: scale-down;
    padding: 5px; /* Some padding */
    width: 150px; /* Set a small width */
    transition: box-shadow .5s ease-in-out;

    &:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
}

.list-item__data {
    margin: $s-size;
    text-align: center;

    @media (min-width: $desktop-breakpoint) {
        margin: 0;
        padding-left: $m-size;
        text-align: left;
    }
}

.list-item--message {
    align-items: center;
    color: $grey;
    justify-content: content;
    padding: $m-size;

    &:hover {
        background: none;
    }
}
