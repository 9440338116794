.image-slide {
  min-height: 100vh;
  background-size: cover !important;
  text-decoration: none;
}

.image-slide-fullheight-row {
  overflow: hidden;
  min-height: 80vh !important;
}

.image-slide-content {
  text-decoration: none !important;
}

.image-slide-content p {
  text-decoration: none !important;
}

.image-slide-link {
  text-decoration: none !important;
}

.image-slide hr {
  border: 1px solid $cream;
  width: 10%;
  margin: 0 auto;
}

.image-slide-type {
  font-weight: 300;
  font-size: 12pt !important;
  text-align: center;
  margin-top: 30px;
  color: $cream;
  word-wrap: break-word;
  text-transform: uppercase;

  @media (min-width: $desktop-breakpoint) {
    font-weight: 200;
    font-size: 18pt !important;
  }
}

.image-slide-title {
  font-weight: 700;
  font-size: 24pt !important;
  text-align: center;
  margin-top: 20px;
  color: $cream;
  word-wrap: break-word;
  line-height: 33pt;
  text-transform: uppercase;

  @media (min-width: $desktop-breakpoint) {
    font-size: 34pt !important;
  }
}

.image-slide-sub-title {
  font-family: Montserrat, Raleway, "Courier new", Tahoma, Helvetica, sans-serif;
  font-size: 14pt !important;
  text-align: center;
  margin-top: 10px;
  color: $cream;
  font-weight: 100;
  word-wrap: break-word;
  font-style: italic;

  @media (min-width: $desktop-breakpoint) {
    font-size: 18pt !important;
  }
}

.image-slide-sub-subtitle {
  font-weight: 400;
  font-size: 12pt !important;
  text-align: center;
  margin-top: 20px;
  color: $cream;
  word-wrap: break-word;

  @media (min-width: $desktop-breakpoint) {
    font-weight: 200;
  }
}

.image-slide-button {
  color: $cream;
  margin: 0 auto;
  border-color: $cream;
  margin-bottom: 30px;
  box-shadow: none;
  transition: background-color 0.5s ease;
}

.image-slide-button:hover {
  background-color: $khaki !important;
  border-color: $khaki !important;
}

.image-slide-button:active {
  background-color: $khaki !important;
  border-color: $khaki !important;
}

.image-slide-button:focus {
  box-shadow: none;
}
