.text-input {
    background: lighten($color: $cream, $amount: 5);
    border: 1px solid $beige;
    height: 50px;
    font-size: $font-size-large;
    font-weight: 300;
    padding: $s-size;
}

.select {
    @extend .text-input;
}

.textarea {
    @extend .text-input;
    height: 10rem;
}