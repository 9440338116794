.page-header {
    background: $cream;
    margin-bottom: $l-size;
    padding: $s-size;
}

.page-header__actions {
    margin-top: $m-size;
}

.page-header__title {
    font-weight: 300;
    margin: 0;
    span {
        font-weight: 700; // bold
    }
}