.slick-dots {
  position: relative;
  margin-top: -70px;
  margin-bottom: 30px;
  color: $cream;
}

.slick-dots li button:before {
  color: $cream;
}

.slick-dots li.slick-active button:before {
  color: $cream;
}
