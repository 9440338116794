.videos-wrapper {
  padding: 80px 45px;
  background-image: linear-gradient(lighten($khaki, 10), darken($khaki, 10));

  @media (min-width: $desktop-breakpoint) {
    padding: 80px 100px;
  }
}

.video-wrapper {
  @extend .videos-wrapper;
}