.our-link-button {
  font-size: 14pt;
  color: $dark-grey;
  text-decoration: none;
  text-align: left;
  // display: inline;
  // white-space: nowrap;
}

.our-link-button:hover {
  color: $khaki;
  text-decoration: none;
}

.our-link-button:focus {
  color: $khaki;
  text-decoration: none;
}

.our-link-button:active {
  color: $khaki;
  text-decoration: none;
}

.our-link-button-active {
  color: $khaki;
  font-weight: 1000;
  text-decoration: none;
}

.our-link-button-page-sidebar-mobile-toggle {
  line-height: 14px;
  font-size: 32px !important;
  color: $khaki;
}