.block-components {
  display: block;
}

.space-around-column-components {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.space-around-row-components {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.space-between-column-components {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.space-between-row-components {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.center-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start-row-components {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}