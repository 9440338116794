.home-page {

}

hr.separator {
  width: 90vw;
  position: relative;
  top: 20px;
  border: none;
  height: 5px;
  background: $brown;
  margin-bottom: 50px;
  border-radius: 50%;
}

.homepage-text {
  padding: 50px 45px;

  @media (min-width: $desktop-breakpoint) {
    padding: 50px;
  }

  a {
    font-weight: 500;
    text-decoration: none;
    transition: color 1s ease;

    &:hover {
      color: red !important;
    }
  }

  img {
    padding-bottom: 15px;
    height: 300px;
    width: 200px;
    border-radius: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
  }

  h3 {
    font-weight: 300;
    text-align: center;
    letter-spacing: 2px;
  }

  h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-top: 10px;
    white-space: nowrap;
  }

  p {
    font-size: 18px;
    text-align: center;
  }

  span {
    font-style: italic;
  }
}

.homepage-text.dark-grey-background {
  background-image: linear-gradient(lighten($dark-grey, 10), darken($dark-grey, 10));
  color: $cream;

  a {
    color: $cream;
  }

  hr {
    border: 1px solid $cream;
    width: 20%;
    margin: 15px auto;
  }
}

.homepage-text.light-blue-background {
  background-image: linear-gradient(lighten($light-blue, 10), darken($light-blue, 10));
  color: $dark-grey;

  a {
    color: $dark-grey;
  }

  hr {
    border: 1px solid $dark-grey;
    width: 20%;
    margin: 15px auto;
  }
}

.homepage-text.brown-background {
  background-image: linear-gradient(lighten($brown, 10), darken($brown, 10));
  color: $cream;

  a {
    color: $cream;
  }

  hr {
    border: 1px solid $cream;
    width: 20%;
    margin: 15px auto;
  }
}

.homepage-text.khaki-background {
  background-image: linear-gradient(lighten($khaki, 10), darken($khaki, 10));
  color: $cream;

  a {
    color: $cream;
  }

  hr {
    border: 1px solid $cream;
    width: 20%;
    margin: 15px auto;
  }
}

.homepage-text.beige-background {
  background-image: linear-gradient(lighten($beige, 10), darken($beige, 10));
  color: $dark-grey;

  a {
    color: $dark-grey;
  }

  hr {
    border: 1px solid $dark-grey;
    width: 20%;
    margin: 15px auto;
  }
}

.homepage-text.black-text {
  color: $dark-grey !important;

  hr {
    border: 1px solid $dark-grey !important;
  }

  a {
    color: $dark-grey !important;
  }

  h3 {
    color: $dark-grey !important;
  }

  h5 {
    color: $dark-grey !important;
  }

  p {
    color: $dark-grey !important;
  }
}

.homepage-text.white-text {
  color: $cream !important;

  hr {
    border: 1px solid $cream !important;
  }

  a {
    color: $cream !important;
  }

  h3 {
    color: $cream !important;
  }

  h5 {
    color: $cream !important;
  }

  p {
    color: $cream !important;
  }
}